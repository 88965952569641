/* Estrutura básica da página */
.gallery-container {
  margin-top: 4rem !important;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Seção de introdução */
.intro-section {
  margin-bottom: 40px;
}

.section-title-obras-realizadas {
  font-size: 36px;
  font-weight: bold;
  color: #f2c166 !important;
  margin-bottom: 20px;
}

.section-subtitle {
  font-size: 1rem !important;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.section-description {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

/* Estilos da galeria */
.gallery-section {
  margin-bottom: 40px;
}

.masonry-grid {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  padding: 0;
}

.masonry-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.masonry-item {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gallery-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
}

.gallery-image:hover {
  transform: scale(1.05);
}

/* Seção de call to action (CTA) */
.cta-section {
  text-align: center;
  margin-top: 40px;
}

.cta-button {
  background-color: #1c77c3;
  color: white;
  font-size: 18px;
  padding: 15px 30px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.cta-button:hover {
  background-color: #165a8b;
}

.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.lightbox-image {
  width: 100%;
  height: auto;
  display: block;
}

.lightbox-close,
.lightbox-prev,
.lightbox-next {
  position: absolute;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
  padding: 10px;
}

.lightbox-close {
  top: 10px;
  right: 20px;
}

.lightbox-prev {
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.lightbox-next {
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
