@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap");

/* Variáveis de cores */
:root {
  --background-dark: #0e0e0e;
  --background-light: #ffffff;
  --secondary-color: #f2cb84;
  --chat-background: #ffffff;
}

/* Página de Simulação */
.simulacao-page {
  background-image: url('../Assets/Images/Simulacao/header.png');
  /* Imagem de fundo */
  background-size: cover;
  background-position: center;
  height: 100vh;
  /* Preenche 100% da tela */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Conteúdo da página */
.simulacao-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10rem;
  margin-right: 4rem;
}

.simulacao-card {
  background-color: #0007 !important;
}

.form-label {
  color: #FFF !important;
}

/* Estilo do texto na esquerda */
.simulacao-text-wrapper {
  color: var(--background-light);
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
}

.simulacao-text-wrapper h2 {
  margin-left: -1%;
}

.simulacao-box-wrapper {
  display: flex;
  justify-content: center;
}

.section-title {
  text-align: left;
  font-size: 48px;
  color: var(--secondary-color);
  margin-bottom: 20px;
  font-weight: bold;
}

.subtitulo-simulation {
  color: rgba(242, 203, 132, 1);
  font-size: 28px;
  font-weight: 200;
  margin-left: 1%;
  border-bottom: 0.2rem solid #f2cb84;
}

.simulacao-text {
  font-size: 16px;
  text-align: left;
  color: var(--background-light);
  max-width: 400px;
  margin-top: 10px;
  /* Para ajustar o espaçamento entre o texto e o subtítulo */
}

/* Estilo do chat na direita */
.simulacao-chat-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.send-sim-button {
  background-color: #f2cb84 !important;
  box-shadow: none !important;
  color: #0e0e0e !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
}

.send-sim-button:hover {
  background-color: #0e0e0e !important;
  color: #f2cb84 !important;
}

.final-value {
  color: #f2cb84 !important;
}

/* ========== Responsividade ========== */
@media (max-width: 1024px) {

  /* Para tablets */
  .simulacao-content {
    flex-direction: column;
    margin-right: 0;
    padding: 2rem 1rem;
  }

  .simulacao-text-wrapper {
    padding-left: 1rem;
    margin-right: 0;
    text-align: center;
  }

  .section-title {
    font-size: 36px;
    text-align: center;
  }

  .subtitulo-simulation {
    font-size: 24px;
    text-align: center;
  }

  .simulacao-text {
    text-align: center;
    max-width: 100%;
  }
}

@media (max-width: 768px) {

  /* Para celulares */
  .simulacao-page {
    height: auto;
    /* Ajusta a altura para conteúdo em dispositivos móveis */
    padding: 1rem;
    background-size: cover;
  }

  .simulacao-content {
    flex-direction: column;
    padding: 2rem 1rem;
    margin-right: 0;
  }

  .simulacao-text-wrapper {
    padding-left: 0;
    text-align: center;
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .section-title {
    font-size: 28px;
    text-align: center;
  }

  .subtitulo-simulation {
    font-size: 18px;
    margin-left: 0;
    text-align: center;
  }

  .simulacao-text {
    max-width: 100%;
    text-align: center;
  }

  .simulacao-chat-wrapper {
    width: 100%;
    /* O chat ocupará toda a largura em celulares */
    margin-top: 2rem;
  }

  /* Ajustes específicos para o conteúdo do chat */
  .chat {
    width: 100%;
    /* Faz o chat ocupar toda a largura da tela */
    padding: 0;
  }
}