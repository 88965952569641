@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@1,14..32,100..900&display=swap');

/* Variáveis de cores */
:root {
  --background-dark: #0E0E0E;
  --background-light: #FFFFFF;
  --secondary-color: #F2CB84;
}

/* ========== Estilização Geral da Página de Produto ========== */
.product-page {
  padding: 0;
  margin: 0;
  background-color: var(--background-dark);
  /* Fundo preto */
}

.product-section {
  padding: 2rem 10rem;
}

.product-wrapper {
  padding: 4rem;
  color: #FFF;
}

.product-table {
  border: solid 0.2rem #F2CB84 !important;
  border-radius: 2rem !important;
  border-collapse: separate;
  padding: 2rem !important;
  color: #FFF !important;
}

.product-table td,
.product-table tr {
  border-color: #0000;
}

.product-table-strip {
  border-left: #F2CB8450 0.1rem solid !important;
}

.product-header {
  color: #F2CB84;
  font-weight: 600;
  font-size: 2rem;
}

.product-margin {
  background-color: #F2CB84;
}

.product-table-text {
  font-weight: 200 !important;
  font-size: 1rem;
}

.product-table-text-1 {
  font-weight: 900 !important;
  font-size: 1.2rem;
}

.product-description {
  font-size: 1.1rem;
  padding: 1rem;
}

.table-section-title {
  text-align: center;
  color: #F2CB84;
}

/* ========== Banner Section ========== */
.products-banner-section {
  background-image: url('../Assets/Images/Produtos/header.png') !important;
  /* Imagem de fundo */
  background-size: cover;
  /* Faz a imagem de fundo cobrir toda a div */
  background-position: center;
  /* Centraliza a imagem de fundo */
  height: 250px;
  /* Altura do banner */
  display: flex;
  align-items: center;
  /* Centraliza o conteúdo verticalmente */
  border-bottom: #F2CB84 solid 0.15rem;
}

.logo {
  width: 200px;
  /* Tamanho do logo */
  margin-left: 2rem;
  /* Margem à esquerda */
}

/* ========== Texto das Seções (Catálogo, Galeria) ========== */
.text-wrapper {
  text-align: right;
  /* Alinha o texto à direita */
  margin-right: 50px;
  /* Margem à direita */
  position: relative;
  display: inline-block;
}

.subtitulo {
  color: rgba(242, 203, 132, 0.6);
  /* Cor dourada opaca */
  font-size: 28px;
  /* Tamanho da fonte */
  font-weight: 200;
  /* Letra extra fina */
  position: absolute;
  top: -20px;
  /* Posição do subtítulo acima do título */
  margin-left: 10.3rem;
  /* Ajuste de margem esquerda */
}

.section-title {
  font-size: 48px;
  /* Aumenta o tamanho do título */
  color: var(--secondary-color);
  /* Cor dourada */
  margin-bottom: 20px;
  /* Margem inferior */
  margin-left: 10rem;
  /* Margem esquerda */
  font-weight: bold;
  position: relative;
}

/* ========== Estilos dos Cards de Produtos ========== */

.mdb-col-catalogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
}

.product-cards {
  display: flex;
  justify-content: center;
  /* Distribui o espaço entre os cards */
  align-items: center;
  margin-top: 20px;
  /* Espaçamento superior */
  flex-wrap: wrap;
  /* Permite que os cards se alinhem conforme o tamanho da tela */
}

.card-wrapper {
  text-align: center;
  /* Alinha o conteúdo dentro do card */
  margin-bottom: 40px;
  /* Espaçamento inferior entre os cards */
  transition: 300ms;
}

.card-wrapper:hover {
  cursor: pointer;
  transform: scale(1.1);
  filter: drop-shadow(#F2CB8450 0rem 0rem 1rem);
}

.card-frame {
  position: relative;
  display: flex;
  justify-content: center;
  /* Centraliza a imagem do produto dentro do frame */
  align-items: center;
  width: 250px;
  /* Ajusta o tamanho do card */
  height: 300px;
  /* Define a altura maior que a largura */
  margin: 0 auto;
  /* Centraliza o card horizontalmente */
  border-radius: 15px;
  /* Adiciona bordas arredondadas */
  overflow: hidden;
  /* Garante que o conteúdo não ultrapasse as bordas */
}

.card-background {
  width: 100%;
  /* Largura total da imagem de fundo do card */
  height: 100%;
  /* Altura total da imagem de fundo do card */
  object-fit: contain;
  /* Garante que a imagem seja completamente visível sem distorção */
  position: absolute;
  top: 0;
  left: 0;
}

.product-image {
  object-fit: contain !important;
  position: relative;
  width: 75% !important;
  max-height: 240px;
  /* Tamanho da imagem do produto */
  height: auto;
  z-index: 2;
  /* Garante que a imagem do produto fique acima do fundo */
  margin: auto;
  /* Centraliza a imagem */
}

.product-name {
  color: var(--background-light);
  /* Cor branca para o nome do produto */
  font-size: 1rem;
  /* Tamanho da fonte do nome do produto */
  margin-top: 10px;
  /* Espaço entre a imagem do produto e o nome */
  font-weight: bold;
  /* Negrito para o nome do produto */
}

/* ========== Estilização da galeria ========== */
.gallery-slider {
  margin: 2rem 4rem 0 4rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.slide {
  padding: 10px;
}

.slide img {
  width: 200px;
}

.nenhum-produto {
  color: #FFFFFF !important;
  font-size: large;

}

.product-holder {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

/* ========== Responsividade ========== */
@media (max-width: 993px) {

  .product-section {
    padding: 2rem 2rem;
  }

  /* Ajustes para tablets */
  .section-title {
    font-size: 36px;
    /* Reduz o tamanho do título */
    margin-left: 5rem;
    /* Reduz a margem esquerda */
  }

  .subtitulo {
    font-size: 22px;
    margin-left: 5rem;
    /* Ajuste de margem esquerda para subtítulo */
  }

  .product-cards {
    flex-wrap: wrap !important;
    /* Permite que os cards quebrem linha em tablets */
  }

  .card-frame {
    width: 220px;
    /* Ajuste de largura do card em tablets */
    height: 280px;
    /* Ajuste de altura do card em tablets */
  }

  .product-image {
    width: 100px;
    /* Reduz o tamanho da imagem do produto */
  }

  .gallery-slider {
    flex-direction: column;
    /* Alinha os itens da galeria em uma coluna */
  }

  .product-holder {
    flex-direction: column;
  }
}

@media (max-width: 768px) {

  /* Ajustes para celulares */
  .banner-section {
    height: 200px;
    /* Reduz a altura do banner */
  }

  .logo {
    width: 150px;
    /* Reduz o tamanho da logo */
    margin-left: 1rem;
    /* Reduz a margem esquerda */
  }

  .section-title {
    font-size: 28px;
    /* Reduz ainda mais o tamanho do título */
    margin-left: 1rem !important;
    /* Centraliza o título */
    text-align: center;
    /* Centraliza o texto no centro da tela */
  }

  .subtitulo {
    font-size: 18px;
    margin-left: 1rem !important;
    /* Centraliza o subtítulo */
    text-align: center;
    /* Centraliza o subtítulo */
  }

  .text-wrapper {
    text-align: center;
    /* Centraliza o texto em dispositivos móveis */
    margin-right: 0;
    /* Remove a margem à direita */
  }

  .product-cards {
    flex-direction: row;
    /* Alinha os cards em uma coluna para dispositivos móveis */
    align-items: center;
  }

  .mdb-col-catalogo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
  }

  .card-wrapper {
    flex-basis: 100%;
    /* Cards ocupam a largura total em dispositivos móveis */
  }

  .card-frame {
    width: 220px;
    /* Ajuste no tamanho do card em dispositivos móveis */
    height: 280px;
    /* Ajuste de altura para dispositivos móveis */
  }

  .product-image {
    width: 100px;
    /* Ajuste no tamanho da imagem do produto */
  }

  .gallery-slider {
    flex-direction: column;
    /* Alinha a galeria verticalmente */
    padding-bottom: 2rem;
    /* Reduz o espaçamento inferior */
  }

  .slide img {
    width: 220px;
    /* Ajusta o tamanho da imagem da galeria em dispositivos móveis */
  }
}

@media (max-width: 425px) {

  /* Ajustes para celulares */
  .banner-section {
    height: 200px;
    /* Reduz a altura do banner */
  }

  .logo {
    width: 150px;
    /* Reduz o tamanho da logo */
    margin-left: 1rem;
    /* Reduz a margem esquerda */
  }

  .section-title {
    font-size: 28px;
    /* Reduz ainda mais o tamanho do título */
    margin-left: 1rem !important;
    /* Centraliza o título */
    text-align: center;
    /* Centraliza o texto no centro da tela */
  }

  .subtitulo {
    font-size: 18px;
    margin-left: 1rem !important;
    /* Centraliza o subtítulo */
    text-align: center;
    /* Centraliza o subtítulo */
  }

  .text-wrapper {
    text-align: center;
    /* Centraliza o texto em dispositivos móveis */
    margin-right: 0;
    /* Remove a margem à direita */
  }

  .product-cards {
    flex-direction: row;
    /* Alinha os cards em uma coluna para dispositivos móveis */
    align-items: center;
  }

  .mdb-col-catalogo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50% !important;
  }

  .card-wrapper {
    flex-basis: 100%;
    /* Cards ocupam a largura total em dispositivos móveis */
  }

  .card-frame {
    width: 180px;
    /* Ajuste no tamanho do card em dispositivos móveis */
    height: 280px;
    /* Ajuste de altura para dispositivos móveis */
  }

  .product-image {
    width: 100px;
    /* Ajuste no tamanho da imagem do produto */
  }

  .gallery-slider {
    flex-direction: column;
    /* Alinha a galeria verticalmente */
    padding-bottom: 2rem;
    /* Reduz o espaçamento inferior */
  }

  .slide img {
    width: 220px;
    /* Ajusta o tamanho da imagem da galeria em dispositivos móveis */
  }
}