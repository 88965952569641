/* Variáveis de cores */
:root {
  --background-dark: #0e0e0e;
  --background-light: #ffffff;
  --secondary-color: #f2cb84;
  --card-background: #070707;
}

/* Página de Contato */
.contact-page {
  background-color: var(--background-dark);
  padding: 0;
}

/* Header */
.contact-header {
  height: 250px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 0.3rem var(--secondary-color);
}

.prime-logo {
  width: 200px;
  margin: 0 auto;
}

/* Seção de Contato */
.contact-section {
  background-color: var(--card-background);
  border-top: solid 0.3rem var(--secondary-color);
  padding: 3rem 5rem;
  border-radius: 10px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 0 2rem;
}

.contact-title {
  text-align: center;
}

.contact-title h3 {
  color: var(--secondary-color);
  font-size: 32px;
}

.contact-description {
  color: var(--background-light);
  text-align: center;
  font-size: 16px;
}

/* Para dispositivos móveis */
.contact-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

/* Informações de Contato (lado esquerdo) */
.contact-info {
  display: none;
  color: var(--background-light);
  display: flex;
  justify-content: space-around;
  height: 100% !important;
  max-height: 100% !important;
}

.contact-info h4 {
  color: var(--secondary-color);
}

.contact-info p {
  margin: 5px 0;
  font-size: 14px;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icon {
  background-color: var(--background-dark);
  color: var(--background-light);
  font-size: 24px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.3s;
  border: 0.1rem solid var(--background-light);
}

.social-icon:hover {
  color: var(--secondary-color);
  transform: scale(1.1);
  border-color: var(--secondary-color);
}

/* Formulário de Contato (lado direito) */
.contact-form {
  gap: 1rem;
  width: 100%;
  max-width: 50%;
}

.input-field {
  width: 100%;
  padding: 0.75rem;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  margin: 0.45rem 0rem;
  background-color: #1e1e1e;
}

.textarea-field {
  height: 150px;
}

/* Botão de envio com o mesmo tamanho dos inputs */
.submit-button {
  background-color: var(--secondary-color);
  color: var(--background-dark);
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  /* Mantém o mesmo tamanho dos inputs */
}

.submit-button:hover {
  background-color: #e6b85d;
}

/* Google Maps */
.map-section {
  margin-top: 3rem;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  padding-bottom: 4rem;
}

.google-maps {
  width: 80%;
  /* Aumenta o tamanho do mapa em dispositivos móveis */
  height: auto;
  aspect-ratio: 16 / 9;
  max-width: 800px;
  border-radius: 10px;
}

/* Ajustes para telas maiores (acima de 768px) */
@media (min-width: 769px) {
  .contact-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100% !important;
  }

  /* Mostrar informações de contato para telas maiores */
  .contact-info {
    display: block;
    flex: 1;
    margin-right: 3rem;
  }

  .contact-section {
    position: relative;
    padding: 2rem 5rem;
    overflow: hidden; /* Garante que o efeito não ultrapasse a área */
}

  /* Formulário de Contato à direita */
  .contact-form {
    margin-left: auto;
    width: 100% !important;
  }

  .google-maps {
    width: 100% !important;
    height: auto;
    aspect-ratio: 16 / 9;
    max-width: 800px;
    border-radius: 10px;
  }

}