@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');

:root {
  --background-dark: #0E0E0E;
  --background-light: #FFFFFF;
  --secondary-color: #F2CB84;
}

#footer {
  display: flex;
  justify-content: center !important;
  align-content: center !important;
  align-items: center !important;
  flex-direction: column;
  background-color: var(--background-dark);
  border-top: solid 0.4rem var(--secondary-color);
  color: var(--background-light);
  font-family: var(--font-family);
  padding: 12px;
  box-sizing: border-box;
  width: 100%;
  /* Certifica-se de que o footer ocupa 100% da largura da tela */
  overflow: hidden;
}

.footer-container {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  flex-wrap: wrap;
  /* Permite que os itens do footer "quebrem linha" em telas menores */
  max-width: 1200px;
  /* Centraliza e limita a largura em telas grandes */
  margin: 0 auto;
  /* Centraliza o conteúdo */
}

#footer-itens {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  justify-content: center !important;
  width: 100%;
  box-sizing: border-box;
}

#footer-left,
#footer-center,
#footer-right {
  padding: 0rem;
  margin: 0;
  flex: 1 1 300px;
  text-align: left;
}

#footer-left {
  margin-bottom: 2rem;
}

.footer-logo img {
  width: 60%;
}

.footer-info p {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.footer-social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.footer-contact p,
.footer-address p {
  font-size: 0.8rem !important;
  margin: 5px 0;
}

.secondary-color {
  color: var(--secondary-color);
}

.social-icon {
  background-color: var(--background-light);
  color: var(--secondary-color);
  font-size: 24px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 15px;
  transition: color 0.3s;
}

.social-icon:hover {
  color: var(--background-dark);
}

.social-icons.footer {
  justify-content: center !important;
  align-items: center !important;
  gap: 1rem !important;
}

.footer-bottom {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}

.cert-logo img {
  width: 100%;
}

/* Ajustes para dispositivos móveis */
@media (max-width: 768px) {
  #footer-itens {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 2rem;
  }

  #footer-left {
    margin-bottom: -8rem;
  }

  #footer-center {
    border-right: none;
    margin-bottom: -3rem;
    padding: 0.5rem;
  }

  #footer-right {
    width: 100% !important;
    margin-top: 3rem !important;
    padding: 1rem !important;
  }

  .footer-logo img {
    width: 80%;
  }

  .social-icon {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }

  .footer-social {
    display: flex;
    flex-direction: row;
  }

  .footer-contact {
    margin-top: 5rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .cert-logo img {
    width: 250px !important;
    height: auto;
  }
}

/* Estilo para dispositivos maiores */
@media (min-width: 769px) {
  #footer {
    padding: 12px;
  }

  .footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #footer-itens {
    display: flex;
    flex-direction: row;
    padding: 1rem;
  }

  #footer-left {
    padding: 0 2rem;
    border-right: solid 3px #F2CB84;
    margin: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: right;
  }

  #footer-center {
    padding: 0 2rem;
    margin: 0 2rem;
  }

  #footer-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0rem;
  }

  .footer-logo img {
    width: 80%;
  }

  .footer-social {
    flex-direction: column;
    align-items: flex-start;
    margin-left: -14rem;
  }

  .footer-info p {
    font-size: 14px;
    line-height: 1.5;
  }

  .footer-contact p,
  .footer-address p {
    font-size: 16px;
    margin: 5px 0;
  }

  .footer-bottom {
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
  }
}