/* Navbar regular para desktop */
.navbar {
  background-color: var(--background-dark);
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  padding: 18px 20px !important;
  position: fixed !important;
  width: 100%;
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: center !important;
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.navbar.hidden {
  transform: translateY(-100%);
  opacity: 0;
  pointer-events: none; /* Evita cliques quando está escondida */
}

.navbar.visible {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-nav {
  position: relative;
  display: flex;
  flex-direction: row !important;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.sim-link-wrapper {
  position: relative;
  background-color: #f2cb84;
  border-radius: 2rem;
  transition: 300ms;
  margin-left: 15px !important;
}

.sim-link {
  color: #0e0e0e !important;
  font-size: 1.2rem !important;
  font-weight: 700 !important;
}

.sim-link-wrapper:hover {
  background-color: #fff0 !important;
  transform: scale(1.2);
}

.navbar-nav .nav-link {
  color: var(--background-light);
  font-weight: 600;
  font-family: var(--font-family);
  font-size: 18px;
  padding: 0 15px;
  text-decoration: none;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link.active {
  color: var(--secondary-color) !important;
}

.navbar-nav .simulation.nav-link.active {
  color: #0e0e0e !important;
}

/* Estilo do botão de menu para mobile */
.menu-btn {
  display: none;
  position: fixed;
  top: 20px;
  right: 20px;
  width: 52px;
  height: 52px;
  z-index: 10000;
  cursor: pointer;
  background-color: #0e0e0e;
  padding: 0.5rem !important;
  border-radius: 25rem;
  font-size: 2rem;
  color: #f2cb84;
  border: 0.1rem solid #f2cb84;
}

.menu-btn .menu-icon {
  width: 100%;
  height: 5px;
  background-color: var(--background-light);
  margin: 6px 0;
}

/* Sidebar lateral */
.sidebar {
  height: 100vh;
  width: 0;
  position: fixed;
  top: 0;
  right: 0;
  background-color: var(--background-dark);
  z-index: 10000;
  transition: 0.3s;
  padding-top: 60px;
  overflow-x: hidden;
}

.sidebar.active {
  width: 250px;
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.sidebar-logo {
  width: 150px;
  margin-top: 10px;
}

.sidebar .close-btn {
  font-size: 36px;
  color: var(--background-light);
  cursor: pointer;
}

.sidebar-nav {
  list-style-type: none;
  padding: 20px 0 0;
  display: flex;
  flex-direction: column;
}

.sidebar-nav .nav-link {
  display: block;
  text-align: left;
  padding: 16px 20px;
  color: var(--background-light);
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
}

.sidebar-nav .nav-link:hover {
  background-color: var(--secondary-color);
  color: var(--background-dark);
}

.sales-button {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #f5f5f5 !important;
  text-transform: initial !important;
}

.onairam-logo {
  width: 250px !important;
  height: auto;
  margin-top: 1rem !important;
}

.onairam-container {
  padding: 2rem !important;
}

.onairam-container img {
  margin-bottom: 2rem !important;
}

.onairam-container p a {
  color: #D9763D !important;
  font-weight: 600 !important;
}

.btn-close-navmodal {
  background-color: #D9763D !important;
  border-radius: 30px !important;
  color: #f5f5f5 !important;
  text-transform: initial !important;
}

.title-seller {
  font-size: 1.3rem !important;
  font-weight: 700;
}

.whatsapp-vendas {
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
  gap: 0.5rem !important;
  margin-top: 0.5rem !important;
}

.whatsapp-vendas span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem !important;
  height: 2rem !important;
  background-color: #25d366;
  border-radius: 50% !important;
  color: white !important;
}

/* Tornar o botão de menu visível apenas em dispositivos menores */
@media (max-width: 991px) {
  .menu-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar {
    display: none !important;
  }

  .sim-link-wrapper {
    position: relative;
    background-color: #f2cb84;
    border-radius: 2rem;
    transition: 300ms;
    margin: 0rem 1rem;
  }
}
