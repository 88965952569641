@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap");
/* Adicionando a biblioteca de animações */
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

/* Variáveis de cores */
:root {
  --background-dark: #0e0e0e;
  --background-light: #ffffff;
  --secondary-color: #f2cb84;
  --font-family: "Montserrat", sans-serif;
}

/* Estilo global */
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: var(--font-family) !important;
}

.home-page {
  background: var(--background-dark);
  color: var(--background-light);
  text-align: center;
  overflow-x: hidden;
}

.home-container {
  padding: 5rem 2rem !important;
  padding-top: 2rem !important;
}

.slick-dots {
  top: 75svh;
}

.slick-dots li.slick-active button:before {
  color: #FFF !important;
  transform: scale(2) !important;
}

.header-elements {
  position: relative;
  /* Mantém o conteúdo na frente do carrossel */
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 5rem !important;
}

.header-text-bg {
  background-color: #0e0e0e50;
  padding: 2rem;
  border-radius: 2rem;
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: start;
  flex-direction: column;
}

/* Estilo para o Header */
.header-section {
  position: relative;
  width: 100%;
  /* Altura total do header */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  /* Garante que as imagens preencham o header */
  padding: 0rem 10% !important;
}

.slider-wrapper {
  position: absolute;
  /* O carrossel ficará como fundo */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
  /* Mantém o carrossel atrás do conteúdo */
}

.slide {
  width: 100%;
  height: 100vh !important;
}

.slide img {
  width: 100% !important;
  height: 100vh !important;
}

.p-gallery-slide img {
  width: 100%;
}

.gallery-slider {
  gap: 20px;
}

.slide-image {
  object-fit: contain !important;
  position: absolute;
  top: 0;
  right: 0 !important;
}

.slick-list {
  overflow: visible !important;
}

.header-container {
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.header-logo {
  width: 250px;
  margin-bottom: 20px;
}

.header-text {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 30px;
  max-width: 400px;
  color: white;
  text-align: left;
}

.header-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 2rem 0rem;
  width: 100%;

}

/* Estilo para os botões */
.header-button {
  padding: 12px 36px;
  font-size: 1rem !important;
  cursor: pointer;
  border-radius: 3rem;
  border: 2px solid #FFF;
  border-radius: 30px !important;
  background: transparent;
  color: #FFF;
  transition: background-color 0.4s ease, color 0.4s ease, box-shadow 0.4s ease;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.header-button .button-icon {
  margin-right: 10px;
}

/* Efeito de brilho nos botões */
.header-button::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0) 70%);
  transition: opacity 0.6s ease;
  opacity: 0;
  z-index: 0;
}

.header-button:hover::before {
  opacity: 1;
  animation: button-glow 1s ease infinite;
}

@keyframes button-glow {

  0%,
  100% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }
}

/* Efeitos ao passar o mouse sobre os botões */
.simule-button:hover {
  background-color: var(--secondary-color);
  color: var(--background-dark);
  box-shadow: 0 0 20px rgba(242, 203, 132, 0.6);
  border-color: var(--secondary-color);
}

.saiba-mais-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--secondary-color);
  border-color: var(--secondary-color);
}

/* Efeito clique */
.header-button:active {
  transform: scale(0.95);
}

/* Efeitos para o botão sobre (hover) */
.sobre-button {
  background-color: var(--secondary-color);
  color: var(--background-dark);
  border: none;
  border-left: solid 0.5rem var(--background-dark);
  border-radius: 5px;
  padding: 0.6rem 1.2rem;
  cursor: pointer;
  transition: background-color 0.4s ease, color 0.4s ease, box-shadow 0.4s ease;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.sobre-button:hover {
  background-color: var(--background-light);
  color: var(--secondary-color);
  box-shadow: 0px 0px 15px rgba(242, 203, 132, 0.6);
}

.sobre-button:active {
  transform: scale(0.97);
}

/* Seção de Cards Glassmorphism */
.cards-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cards-glass-group {
  display: flex;
}

.cards-banner {
  display: flex;
}

.cards-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-glass {
  background: rgba(255, 255, 255, 0.13);
  padding: 1rem;
  backdrop-filter: blur(3px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
  width: 250px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.card-glass:hover {
  transform: translateY(-10px);
}

.card-content {
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
}

.card-icon-glass {
  font-size: 2.5rem;
  margin-right: 10px;
  color: var(--secondary-color);
}

.card-title-thin {
  font-size: 0.7rem !important;
  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto !important;
  font-weight: 200 !important;
  font-style: italic !important;
  color: var(--secondary-color) !important;
}

.card-title-bold {
  font-size: 0.8rem !important;
  font-weight: 400;
  color: var(--secondary-color) !important;
}

/* Animação para ícones */
.animate-icon {
  animation: subtle-pulse 3s infinite;
}

.catalog-section {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 3rem 0;
  padding-top: 5rem !important;
  background: var(--background-dark);
}

.catalog-button {
  padding: 12px 24px;
  font-size: 1rem;
  font-family: var(--font-family);
  cursor: pointer;
  border-radius: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.view-catalog {
  background: transparent;
  border: 2px solid var(--secondary-color);
  color: var(--secondary-color);
}

.view-catalog:hover {
  background: var(--secondary-color);
  color: var(--background-dark);
  box-shadow: 0 0 15px rgba(242, 203, 132, 0.6);
}

.download-catalog {
  background: var(--secondary-color);
  border: none;
  color: var(--background-dark);
  box-shadow: 0 0 10px rgba(242, 203, 132, 0.6);
}

.download-catalog:hover {
  background: var(--background-light);
  color: var(--secondary-color);
  box-shadow: 0 0 15px rgba(242, 203, 132, 0.8);
}

.catalog-button:active {
  transform: scale(0.97);
}


@keyframes subtle-pulse {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }
}

/* Seção de Sobre */
.sobre-section {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sobre-section.reverse {
  flex-direction: row-reverse;
}

.sobre-img-col {
  flex: 1;
}

.sobre-img {
  width: 90%;
  height: auto;
  object-fit: cover;
  border-radius: 20px;
  margin: 0 auto;
}

.sobre-content-col {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sobre-title {
  color: var(--secondary-color);
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.sobre-title .title-icon {
  margin-right: 10px;
}

.sobre-text {
  color: var(--background-light);
  margin-bottom: 1.5rem;
  text-align: left;
}

/* Seção de Destaques */
.destaques-section {
  margin: 4rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 14px;
}

.destaques-titulo {
  max-width: 400px;
  width: auto !important;
}

.destaques-cards {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 2rem 0;
}

.card-destaque {
  background-color: var(#FFF0);
  border-radius: 5px 30px 5px 30px;
  padding: 10px;
  text-align: center;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: transform 0.3s ease;
}

.card-destaque:hover {
  transform: scale(1.05);
}

.card-icon-glass {
  font-size: 2rem;
  margin: 12px;
  margin-left: 0;
  color: var(--background-light);
  text-align: center;
}

.card-icon {
  font-size: 2.5rem;
  margin: 12px;
  color: var(--secondary-color);
  text-align: center;
}

.card-title-bold {
  font-size: 16px;
  font-weight: bold;
  color: #FFF !important;
  text-align: center;
}

/* Seção de Vídeo */
.video-container {
  margin: 4rem 0;
  width: 100%;
}

.video-container iframe {
  width: 100% !important;
  height: auto;
  aspect-ratio: 16 / 9;
  max-width: 800px;
}

/* Estilos responsivos */
@media (max-width: 991px) {
  .cards-section {
    display: none;
  }

  /* Ajustes no Header */
  .header-container {
    margin-left: 0;
    align-items: center;
    text-align: center;
    margin-bottom: 3rem;
    padding: 0 20px;
  }

  .header-logo {
    width: 80%;
  }

  .header-text {
    font-size: 1rem;
    max-width: 100%;
  }

  .header-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .header-button {
    width: 100%;
    justify-content: center;
  }

  /* Ajustes nos Cards */
  .cards-glass-group {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .card-glass {
    width: 90%;
    margin-bottom: 20px;
  }

  /* Ajustes na Seção Sobre */
  .sobre-section {
    flex-direction: column;
    align-items: center;
  }

  .sobre-section.reverse {
    flex-direction: column;
  }

  .sobre-img {
    width: 100%;
    margin-bottom: 1rem;
  }

  .sobre-content-col {
    text-align: center;
    padding: 0 20px;
  }

  /* Ajustes na Seção de Destaques */
  .destaques-cards {
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .destaques-imagem {
    width: 100%;
  }

  .card-destaque {
    width: 50%;
    margin-bottom: 20px;
  }

  /* Ajustes na Seção de Vídeo */
  .video-container iframe {
    width: 100%;
    height: 30vh;
  }

  /* Resolvendo largura excedente */
  body,
  html {
    overflow-x: hidden;
  }
}

@media (max-width: 425px) {
  .destaques-cards {
    flex-direction: column;
  }

  .card-destaque {
    width: 80% !important;
  }

  .header-section {
    min-height: 85svh !important;
  }
}