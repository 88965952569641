@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@1,14..32,100..900&display=swap");
/* Adicionando a biblioteca de animações */
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");

/* Estilização exclusiva para a página Sobre */

.sobre-page {
  background-color: #0e0e0e;
  padding: 5rem 0;
  color: white;
  margin: 0 !important;
}

/* Animação de entrada para o logotipo */
.sobre-logo-section {
  margin-bottom: 20px;
  margin-left: 8rem;
  animation: fadeInDown 1s ease-in-out;
}

.sobre-prime-logo {
  width: 250px;
  max-width: 100%;
}

/* Animações e estilo para a seção de conteúdo */
.sobre-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7rem;
  margin-bottom: 8rem;
  animation: fadeInUp 1.2s ease-in-out;
}

.sobre-image-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 !important;
}

.sobre-product-image {
  width: 100%px;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  transition: transform 0.3s ease;
}

/* Efeito ao passar o mouse na imagem */
.sobre-product-image:hover {
  transform: scale(1.05);
}

.sobre-text-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 0 !important;
}

.sobre-text-wrapper {
  position: relative;
}

/* Efeito de brilho no título */
.sobre-title-overlay {
  position: relative;
  display: inline-block;
  z-index: 2;
  border-radius: 10px;
  padding: 5px;
  background-color: #0e0e0e;
  animation: fadeInLeft 1s ease-in-out;
}

.sobre-titulo {
  color: #f2cb84;
  font-size: 36px;
  font-weight: 600;
  position: relative;
  z-index: 1;
}

.sobre-descricao {
  font-size: 16px;
  color: white;
  line-height: 1.4;
  margin-bottom: 10px;
  animation: fadeInRight 1.3s ease-in-out;
}

/* Animações e estilo para a seção de cards */
.sobre-card-section {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 30px;
  animation: fadeInUp 1.5s ease-in-out;
}

.sobre-card-wrapper {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Efeito ao passar o mouse no card */
.sobre-card-wrapper:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 15px rgba(242, 203, 132, 0.6);
}

.sobre-card-icon {
  color: #f2cb84;
  transition: color 0.3s ease, transform 0.3s ease;
}

/* Efeito ao passar o mouse no ícone do card */
.sobre-card-wrapper:hover .sobre-card-icon {
  color: #ffffff;
  transform: rotate(15deg);
}

.sobre-card-overlay {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 60%;
  height: 35px;
  background-color: #0e0e0e;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s ease-in-out;
}

.sobre-card-title {
  color: #f2cb84;
  font-size: 14px;
  font-weight: bold;
}

.sobre-card-description {
  margin-top: 10px;
  font-size: 1rem;
  color: white;
  max-width: 60%;
  animation: fadeIn 1.2s ease-in-out;
}

.sobre-card-holder {
  display: flex;
  justify-content: space-around;
}

/* Animações chave */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Responsividade para dispositivos móveis */
@media (max-width: 768px) {
  .sobre-logo-section {
    margin-left: 2rem;
  }

  .sobre-content {
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 4rem;
    padding: 0 2rem;
  }

  .sobre-image-section {
    justify-content: center;
  }

  .sobre-product-image {
    width: 250px;
    height: 250px;
  }

  .sobre-text-wrapper {
    max-width: 100%;
    text-align: center;
  }

  .sobre-titulo {
    font-size: 28px;
  }

  .sobre-descricao {
    font-size: 14px;
  }

  /* Ajuste de cards para tela menor */
  .sobre-card-section {
    flex-direction: column;
    gap: 1rem;
  }

  .sobre-card-title {
    font-size: 12px;
  }

  .sobre-card-description {
    font-size: 0.8rem;
  }
}

/* Responsividade para tablets */
@media (max-width: 1024px) {
  .sobre-content {
    gap: 3rem;
  }

  .sobre-product-image {
    width: 280px;
    height: 280px;
  }

  .sobre-titulo {
    font-size: 32px;
  }

  .sobre-card-section {
    gap: 1.5rem;
  }
}