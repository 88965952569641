.floating-whatsapp-button {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    background-color: #25d366;
    color: white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 1000;
    transition: transform 0.3s ease-in-out, box-shadow 0.3ss ease-in-out;
  }
  
  .floating-whatsapp-button:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  }
  
  /* Animação de pulsar */
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .floating-whatsapp-button {
    animation: pulse 2s infinite;
  }
  