@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@1,14..32,100..900&display=swap");

/* Variáveis de cores */
:root {
  --background-dark: #0e0e0e;
  --background-light: #ffffff;
  --secondary-color: #f2cb84;
  --card-background: #070707;
}

/* ========== Estilização Geral da Página ========== */
.differential-page {
  padding: 0;
  margin: 0;
  background-color: var(--background-dark);
}

/* ========== Banner Section ========== */
.diff-banner-section {
  background-image: url("../Assets/Images/Produtos/header.png");
  background-size: cover;
  background-position: center;
  height: 250px;
  display: flex;
  align-items: center;
}

.logo {
  width: 200px;
  margin-left: 2rem;
}

/* ========== Texto das Seções (Título e Subtítulo) ========== */
.text-wrapper {
  text-align: right;
  margin-right: 50px;
  position: relative;
  display: inline-block;
}

.subtitulo {
  color: rgba(242, 203, 132, 0.6);
  font-size: 28px;
  font-weight: 200;
  position: absolute;
  top: -20px;
  margin-left: 10.3rem;
}

.section-title {
  font-size: 48px;
  color: var(--secondary-color);
  margin-bottom: 20px;
  margin-left: 10rem;
  font-weight: bold;
  position: relative;
}

/* Animação sutil para ícones decorativos */
.icon-subtitle {
  margin-left: 10px;
  animation: subtle-pulse 3s infinite;
}

@keyframes subtle-pulse {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }
}

/* ========== Estilos da Sessão de Tecnologia ========== */
.technology-section {
  padding: 4rem 0;
  border-top: solid 0.5rem var(--secondary-color);
}

.technology-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.technology-card {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  width: 280px;
  height: 280px;
  background-color: var(--card-background);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 2rem;
  transition: transform 0.3s ease;
}

.technology-card:hover {
  transform: translateY(-10px);
}

.icon {
  font-size: 80px;
  color: var(--background-light);
}

.technology-content {
  padding: 1.5rem;
  text-align: center;
}

.card-title {
  font-size: 20px;
  color: var(--secondary-color);
  margin-bottom: 0.8rem;
}

.card-text {
  font-size: 13px;
  color: var(--background-light);
  text-align: center;
}

.card-spaces {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
}

/* ========== Estilos da Sessão de Benefícios ========== */
.benefits-section {
  padding: 4rem 0;
}

.benefits-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.benefits-card {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  width: 350px;
  height: 350px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.benefits-card:hover {
  transform: translateY(-10px);
}

.benefits-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(120%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.benefits-content {
  position: relative;
  z-index: 2;
  color: var(--background-light);
  padding: 1.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-title {
  font-size: 24px;
  color: var(--secondary-color);
  margin-bottom: 1rem;
}

.card-text {
  font-size: 16px;
  color: var(--background-light);
}

/* Efeito de animação sutil para os ícones */
.icon {
  animation: icon-bounce 2s ease-in-out infinite;
}

@keyframes icon-bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }
}

/* Responsividade para dispositivos móveis */
@media (max-width: 768px) {
  .subtitulo {
    font-size: 24px;
  }

  .section-title {
    font-size: 36px;
  }

  .technology-card,
  .benefits-card {
    width: 100%;
    height: auto;
  }
}