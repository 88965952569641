/* Layout Principal */
.product-page {
  padding: 6rem 0;
  background-color: #0E0E0E;
  color: #FFF !important;
}

h2 {
  border-bottom: #F2CB84 solid 0.2rem;
  padding-bottom: 1rem;
  color: #F2CB84 !important;
}

/* Primeira Seção - Título e Imagem */
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.product-title {
  font-weight: 300 !important;
}

.align-items-center {
  align-items: center;
}

.text-right {
  text-align: right;
}

.product-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #F2CB84;
}

.product-image {
  width: 100%;
  max-width: 350px;
  border-radius: 10px;
  object-fit: cover;
}

/* Galeria de Imagens */
.gallery-title {
  font-size: 2rem;
  font-weight: bold;
  color: #F2CB84;
  text-align: center;
  margin-bottom: 2rem;
}

.product-gallery-image {
  width: 100%;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.2s ease-in-out;
}

.product-gallery-image:hover {
  transform: scale(1.2);
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.content-column {}

.text-center {
  text-align: center;
  color: #F2CB84;
}

/* Segunda Seção - Descrição e Tabela */
.mt-5 {
  margin-top: 3rem;
}

.product-description {
  padding: 0;
  margin-top: 0rem;
  color: #FFF !important;
}

.product-holder {
  justify-content: start;
  align-items: start;
}

.product-description h2 {
  font-size: 1.4rem;
  color: #FFF;
  margin-bottom: 1rem;
  margin-top: 0rem;
}

.product-description p {
  font-size: 0.9rem;
  line-height: 1.5;
  color: #FFF;
  white-space: pre-line;
}

.table-section-title {
  font-size: 1.4rem !important;
}

.table-section-title {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #F2CB84;
}

/* Estilizando a Tabela */
.product-table {
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem !important;
}

.product-header th {
  padding: 0.5rem;
  text-align: center;
  background-color: #f2f2f200;
  font-weight: bold;
  color: #F2CB84;
  font-size: 1.6rem;
}

.product-table td {
  padding: 0.6rem;
  border: 1px 0px solid #ddd;
  background-color: #fff0;
}

.product-table-text {
  border-left: #F2CB84 0.1rem solid !important;
  border-bottom: #F2CB84 0.1rem solid !important;
  padding: 0.25rem !important;
  padding-left: 1rem !important;
  font-size: 0.9rem;
}

.product-table-text-1 {
  font-weight: bold;
  background-color: #f9f9f9;
  font-size: 0.9rem;
  padding: 0.25rem;
  border-bottom: #F2CB84 0.1rem solid !important;
}

.product-img-wrapper {
  display: flex;
  justify-content: center;
}

.product-img-wrapper img {
  object-fit: contain;
  max-width: 80%;
}

.product-img-3 img {
  max-height: 512px !important;
  width: 100%;
  object-fit: contain;
}

.product-img-2 img {
  object-fit: contain;
}

.legend-item li {
  font-size: 0.85rem;
}

/* Responsividade */
@media (max-width: 991px) {
  .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .type-2-wrapper {
    flex-direction: column-reverse;
  }

  .product-wrapper {
    margin: 0 auto;
    padding: 3rem 5% !important;
  }

  .product-title {
    font-size: 2rem;
    text-align: center;
  }

  .product-image {
    max-width: 250px;
    margin: 0 auto;
  }

  .gallery-title,
  .product-description h2,
  .table-section-title {
    font-size: 1.5rem;
    text-align: center;
  }

  .product-description p {
    font-size: 1rem;
  }
}

@media (max-width: 425px) {
  .product-title {
    font-size: 1.8rem;
  }

  .product-image {
    max-width: 200px;
  }

  .product-description p {
    font-size: 0.85rem;
  }

  .table-section-title {
    font-size: 1.3rem;
  }

  .product-gallery-image {
    max-width: 100%;
  }
}

/* Animações */
.animated-title {
  animation: fadeInDown 1s ease-in-out;
}

.animated-fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Hover nas imagens */
.product-gallery-image:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* Ajustes de estilos já presentes */
.logo {
  max-width: 200px;
}

.product-table {
  background-color: #0e0e0e;
  border-radius: 8px;
}

.product-img-wrapper {
  overflow: hidden;
  display: flex;
  justify-content: center;
}