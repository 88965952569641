/* Container da página */
.certificados-container {
  padding: 6rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f5f5f5; /* Fundo neutro claro */
  border-radius: 20px;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1);
}

/* Introdução */
.certificados-intro {
  text-align: left;
  margin-bottom: 50px;
  padding: 0 3rem !important;
  margin-top: 1rem !important;
}

.certificados-title {
  font-size: 42px;
  font-weight: 700 !important;
  color: #e7af49 !important; /* Cor escura para o título */
  margin-bottom: 20px;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.certificados-description {
  font-size: 18px;
  color: #666; /* Cor suave para o texto */
  line-height: 1.7;
  margin-bottom: 30px;
  font-style: italic;
}

/* Estilos da lista de certificados */
.certificados-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

/* Card de cada certificado */
.certificado-item {
  max-width: 330px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.certificado-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.15);
}

.certificado-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 250px;
  padding: 1rem !important;
  border-radius: 30px !important;
}

.certificado-name {
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 20px;
}

.certificado-actions {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Botões de Ação */
.certificado-button {
  width: 100%;
  font-size: 16px;
  padding: 12px;
  border-radius: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  background-color: #e7af49 !important;
  box-shadow: none !important;
  text-transform: initial;
  border: #e7af49 solid 2px !important;
}

/* Efeitos de hover nos botões */
.certificado-button:hover {
  background-color: transparent !important;
  color: #e7af49 !important;
  transform: translateY(-5px);
}

.certificado-button.baixar {
  background-color: transparent !important;
  color: #e7af49 !important;
}

.certificado-button.baixar:hover {
  background-color: #e7af49 !important;
  color: #f5f5f5 !important;
}

.certificado-button.visualizar {
  background-color: #0069d9; /* Cor azul para o botão de visualizar */
}

.certificado-button.visualizar:hover {
  background-color: #0056b3;
}

/* Ícones dentro dos botões */
.button-icon {
  margin-right: 10px;
  font-size: 20px;
}

@media (max-width: 768px) {
    .certificados-intro {
        padding: 0 !important;
    }
}
